import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.css']
})
export class CropComponent implements OnInit, OnDestroy {
  active = 'top';
  constructor() { }

  ngOnInit(): void {
    var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        // var body = document.getElementsByTagName('body')[0];
        // body.classList.add('index-page');
  }

  ngOnDestroy(){
    
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
    // var body = document.getElementsByTagName('body')[0];
    //     body.classList.remove('index-page');
  }

}
