import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
//import { SectionsModule } from './sections/sections.module';
//import { ComponentsModule } from './components/components.module';
//import { ExamplesModule } from './examples/examples.module';

import { AppComponent } from './app.component';
import { PresentationComponent } from './presentation/presentation.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

// import { PresentationModule } from './presentation/presentation.module';
import { HomeComponent } from './home/home.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { FooterComponent } from './shared/footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { LrpcalcComponent } from './lrpcalc/lrpcalc.component';
import { CropComponent } from './crop/crop.component';

import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { DonotsellmydataComponent } from './donotsellmydata/donotsellmydata.component';
import { GetaquoteComponent } from './getaquote/getaquote.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        HomeComponent,
        FooterComponent,
        LrpcalcComponent,
        CropComponent,
        DonotsellmydataComponent,
        GetaquoteComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        //NgbNav,
        FormsModule,
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        //PresentationModule,
        //SectionsModule,
        //ComponentsModule,
        //ExamplesModule,
        MatMenuModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD6S-nobW8vCcWBMSqlcvqxRE7Vyrio5vU'
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
