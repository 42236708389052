import { Component, OnInit } from '@angular/core';
import { SendEmailService } from '../shared/send-email.service';
import {NgForm} from '@angular/forms';


@Component({
  selector: 'app-donotsellmydata',
  templateUrl: './donotsellmydata.component.html',
  styleUrls: ['./donotsellmydata.component.css']
})
export class DonotsellmydataComponent implements OnInit {
  contactPressed : boolean=false;
  formObject : {};

  active = 'top';
  constructor(private sendEmailService: SendEmailService) { }



  sendEmailPressed(myForm: NgForm) {
    // console.log("RAW FORM")
    // console.log(myForm.value)
    
    this.formObject = myForm.value
    // console.log("formObject")
    this.formObject['formType'] = "DoNotSellMyData"
    // console.log(this.formObject)


    if(myForm.valid){
       this.sendEmailService.sendDoNotSellDataEmail(this.formObject);
       this.contactPressed=true;
    }
    
   

}















  ngOnInit(): void {
    var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        // var body = document.getElementsByTagName('body')[0];
        // body.classList.add('index-page');
  }

  ngOnDestroy(){
    
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
    // var body = document.getElementsByTagName('body')[0];
    //     body.classList.remove('index-page');
  }

}
