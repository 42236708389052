import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-lrpcalc',
  templateUrl: './lrpcalc.component.html',
  styleUrls: ['./lrpcalc.component.css']
})
export class LrpcalcComponent implements OnInit, OnDestroy {
  //SMOOTH SCROLL TRY????
  //@ViewChild('tablesection') private myScrollContainer: ElementRef;

  scrollToElement(el): void {
    console.log("SCROLL CLICKED");
    //console.log(this.myScrollContainer.nativeElement.scrollHeight);
    
    const element = document.querySelector("#tablesection");
    console.log(element);
    if (element) { element.scrollIntoView(); }
   

  }

  maxDate: Date;
  //color: ThemePalette = 'warn';
  myFilter = (d: Date | null): boolean => {
    //var today = new Date();
    const day = (d || new Date()).getDay();
    
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6
  }
  LRPData = [];
  commodityCode='';
  TypeCode='';
  StateCode='';
  EndorsementLengthCount=''
  EndorsementLengthCode=''
  SalesEffectiveDate= '';
  EndDate= [];
  //EffectiveDate= [];
  ExpectedEndingValueAmount= [];
  webServiceURLDEV = ''
  webServiceURLPROD = ''
  loadingSpinner : boolean=false;
  cattleTypes = [
    {displayName:'Feeder Steers Weight 1 (<600 lbs)',
    typeCode:'809'},
    {displayName:'Feeder Steers Weight 2 (600-1000 lbs)',
    typeCode:'810'},
    {displayName:'Feeder Heifers Weight 1 (<600 lbs)',
    typeCode:'811'},
    {displayName:'Feeder Heifers Weight 2 (600-1000 lbs)',
    typeCode:'812'},
    {displayName:'Feeder Brahman Weight 1 (<600 lbs)',
    typeCode:'813'},
    {displayName:'Feeder Brahman Weight 2 (600-1000 lbs)',
    typeCode:'814'},
    {displayName:'Feeder Dairy Weight 1 (<600 lbs)',
    typeCode:'815'},
    {displayName:'Feeder Dairy Weight 2 (600-1000 lbs)',
    typeCode:'816'},
    {displayName:'Fed Heifers and Steers (1000-1600 lbs)',
    typeCode:'820'}
  ];
  swineTypes = [
    {displayName:'Unborn Swine',
    typeCode:'821'}
    ,{displayName:'No Type',
    typeCode:'997'}
  ];
  stateCodes = [
    {displayName:'OKlahoma',
    Code:'40'}
    // ,{displayName:'No Type',
    // typeCode:'997'}
  ]
  
  selectedItems = [];

  dropdownList1 = [];
  selectedItems1 = [];
  dropdownSettings1 = {};
  
  typeSelection:string='';
  stateSelection:string='';
  livestockSelection:string='';
  dateSelection:Date;
  yesterday: Date;
  
  constructor(private http: HttpClient) { 
    const today = new Date()
    //console.log(today.getTime())
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate()-1;
    const currentTime = today.getHours();
    console.log("CURRENT HOUR: " +currentTime)
    
    //this.maxDate = new Date(currentYear, currentMonth, currentDay+1);
    
    if(currentTime >= 17){
      //Max Date is Today
      console.log("AFTER 5PM")
      this.maxDate = new Date(currentYear, currentMonth, currentDay+1);
    }
    else{
      //Max Date is Yesterday
      console.log("BEFORE 5PM")
      this.maxDate = new Date(currentYear, currentMonth, currentDay);
    }

  }

  getData(){
    this.LRPData = [];
    this.loadingSpinner=true;
    console.log("GET DATA PRESSED")
    console.log(this.dateSelection)
    console.log(this.livestockSelection)
    console.log(this.typeSelection)
    console.log(this.stateSelection)
    // this.commodityCode = '0801'
    // this.TypeCode = '809'
    //this.StateCode = '40'
    this.EndDate = [];
    this.StateCode = this.stateSelection
    this.commodityCode = this.livestockSelection
    this.TypeCode = this.typeSelection
  
  //FIX FOR FED CATTLE HAVING DIFFERENT COMMODITY CODE!
  if(this.TypeCode == "820"){
    console.log("COMMODITY CODE UPDATED")
    this.commodityCode = "0802"
  }
  
    //this.dateSelection = new Date('YYYY-MM-DD');
    //this.yesterday = new Date(Date.now() - 86400000)
    //this.dateSelection = this.yesterday;
      var tempSalesEffectiveDate = new Date(this.dateSelection)
      //console.log(tempSalesEffectiveDate)
        var salesDay = tempSalesEffectiveDate.getDate();
        var salesMonth = tempSalesEffectiveDate.getMonth()+1;
        var salesYear = tempSalesEffectiveDate.getFullYear();
        this.SalesEffectiveDate = salesMonth+'/'+salesDay+'/'+salesYear;
        console.log(this.SalesEffectiveDate)
    //this.SalesEffectiveDate = '03/31/2021'
    this.EndorsementLengthCount = 'XXX'
    this.EndorsementLengthCode = 'XXX'
  
  
    
    this.webServiceURLDEV = 'http://localhost:7071/api/ChelfLRPAPI?commodityCode='+this.commodityCode+'&TypeCode='+this.TypeCode+'&EndorsementLengthCount='+this.EndorsementLengthCount+'&EndorsementLengthCode='+this.EndorsementLengthCode+'&SalesEffectiveDate='+this.SalesEffectiveDate+'&StateCode='+this.StateCode
    this.webServiceURLPROD = 'https://www.chelfinsurance.com/api/ChelfLRPAPI/?commodityCode='+this.commodityCode+'&TypeCode='+this.TypeCode+'&EndorsementLengthCount='+this.EndorsementLengthCount+'&EndorsementLengthCode='+this.EndorsementLengthCode+'&SalesEffectiveDate='+this.SalesEffectiveDate+'&StateCode='+this.StateCode
  
  
    //console.log(this.webServiceURLPROD)
  
    this.http.get<any>(this.webServiceURLPROD).subscribe(data => {
      //this.LRPData = data;
  
      data.forEach(value => {
        //console.log(value)
        var EndorsementLengthCount = parseFloat(value.EndorsementLengthCount)
        //console.log(EndorsementLengthCount)
        var LivestockCoverageLevelPercent = Number(value.LivestockCoverageLevelPercent)*100
        LivestockCoverageLevelPercent= parseInt(LivestockCoverageLevelPercent.toFixed(0))
        //console.log(LivestockCoverageLevelPercent)
        if(!this.LRPData[EndorsementLengthCount]){
          this.LRPData[EndorsementLengthCount]=[];
          this.LRPData[EndorsementLengthCount].push(value);
  
        }
        else{
          this.LRPData[EndorsementLengthCount].push(value);
        }
        //this.LRPData[key].push(value);
  
        if(!this.EndDate[EndorsementLengthCount])
        {
          console.log("ENDORSEMENT LENGTH "+EndorsementLengthCount)
          

          var dateArray = value.EndDate.split('T')[0].split('-')
          console.log("DATE ARRAY")
          console.log(dateArray)


          var endDay = dateArray[2]
          var endMonth = dateArray[1]
          var endYear = dateArray[0]

          //OLD BROKEN TIME ZONES!
          //var tempEndDate = new Date(value.EndDate)
          // var endDay = tempEndDate.getDate();
          // var endMonth = tempEndDate.getMonth()+1;
          // var endYear = tempEndDate.getFullYear();

          this.EndDate[EndorsementLengthCount] = endMonth+'/'+endDay+'/'+endYear;
          console.log(this.EndDate[EndorsementLengthCount]);
        }
        // if(!this.SalesEffectiveDate[EndorsementLengthCount])
        // {
        //   var tempEndDate = new Date(value.SalesEffectiveDate)
        //   var endDay = tempEndDate.getDay();
        //   var endMonth = tempEndDate.getMonth()+1;
        //   var endYear = tempEndDate.getFullYear();
        //   this.SalesEffectiveDate[EndorsementLengthCount] = endMonth+'/'+endDay+'/'+endYear;
        // }
        if(!this.ExpectedEndingValueAmount[EndorsementLengthCount])
        {
          this.ExpectedEndingValueAmount[EndorsementLengthCount] = value.ExpectedEndingValueAmount;
        }
  
  
  
    })
  
      //console.log("PRE SORT")
      //console.log(this.LRPData[13])
      console.log(this.LRPData)
      if(this.LRPData[13]){
        this.LRPData[13].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      if(this.LRPData[17]){
        this.LRPData[17].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      if(this.LRPData[21]){
        this.LRPData[21].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      if(this.LRPData[26]){
        this.LRPData[26].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      if(this.LRPData[30]){
        this.LRPData[30].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      if(this.LRPData[34]){
        this.LRPData[34].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      if(this.LRPData[39]){
        this.LRPData[39].sort((a,b) => 0 - (a.LivestockCoverageLevelPercent > b.LivestockCoverageLevelPercent ? 1 : -1));
      }
      this.loadingSpinner=false;
      //console.log("POST SORT")
      //console.log(this.LRPData[13])
  
      //console.log(this.EndDate)
      //console.log(this.ExpectedEndingValueAmount)
      //this.sortByEndorsementLength(data)

      const element = document.querySelector("#tablesection");
      //console.log(element);
      if (element) { element.scrollIntoView({behavior: "smooth"}); }
  
  })     
  
  
  
  
  }






  ngOnInit(): void {
    var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        if ( window.innerWidth >= 770 )
        { var header = document.getElementById('lrphead');
        header.classList.add('page-header-small');  }
        

    //console.log("HOME LOADED!")
    this.dropdownSettings1 = {
      singleSelection: true,
      text:"Single Select",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      //enableSearchFilter: true,
      classes:"",
      lazyLoading: true
    };



  var dummyURLPROD = 'https://www.chelfinsurance.com/api/ChelfLRPAPI'
  var dummyURLDEV = 'http://localhost:7071/api/ChelfLRPAPI'

    this.http.get<any>(dummyURLPROD).subscribe(data => {});
  }
//   onItemSelect(item:any){
//     console.log(item);
//     console.log(this.selectedItems);
// }
// OnItemDeSelect(item:any){
//     console.log(item);
//     console.log(this.selectedItems);
// }
// onSelectAll(items: any){
//     console.log(items);
// }
// onDeSelectAll(items: any){
//     console.log(items);
// }

ngOnDestroy(){
  
  var navbar = document.getElementsByTagName('nav')[0];
  navbar.classList.remove('navbar-transparent');
}

}

