import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(private http: HttpClient) { }

  sendDoNotSellDataEmail(postData){
    console.log(postData)
    
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
      //'responseType': 'application/json' as 'json'
    };

      var webServiceURLDEV = "http://localhost:7071/api/SendEmail"
      var webServiceURLPROD = "https://www.chelfinsurance.com/api/SendEmail"

    this.http.post(webServiceURLPROD, postData, httpOptions).subscribe(
          (response) => 
          console.log(response),
          
          (error) => console.log(error)
        )


}

  sendContactUsEmail(postData){
    //console.log(postData)
    
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
      //'responseType': 'application/json' as 'json'
    };

      var webServiceURLDEV = "http://localhost:7071/api/SendEmail"
      var webServiceURLPROD = "https://www.chelfinsurance.com/api/SendEmail"

    this.http.post(webServiceURLPROD, postData, httpOptions).subscribe(
          (response) => 
          console.log(response),
          
          (error) => console.log(error)
        )


}


sendGetAQuoteEmail(postData){
  //console.log(postData)
  
  const httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
    //'responseType': 'application/json' as 'json'
  };

    var webServiceURLDEV = "http://localhost:7071/api/SendEmail"
    var webServiceURLPROD = "https://www.chelfinsurance.com/api/SendEmail"

  this.http.post(webServiceURLPROD, postData, httpOptions).subscribe(
        (response) => 
        console.log(response),
        
        (error) => console.log(error)
      )


}


}
