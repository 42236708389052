import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

//import { PresentationComponent } from './presentation/presentation.component';
import { HomeComponent } from './home/home.component';
//import { ComponentsComponent } from './components/components.component';
//import { SectionsComponent } from './sections/sections.component';
// import { AboutusComponent } from './examples/aboutus/aboutus.component';
// import { BlogpostComponent } from './examples/blogpost/blogpost.component';
// import { BlogpostsComponent } from './examples/blogposts/blogposts.component';
// import { ContactusComponent } from './examples/contactus/contactus.component';
// import { EcommerceComponent } from './examples/ecommerce/ecommerce.component';
// import { LandingComponent } from './examples/landing/landing.component';
// import { LoginComponent } from './examples/login/login.component';
// import { ProductpageComponent } from './examples/productpage/productpage.component';
// import { ProfileComponent } from './examples/profile/profile.component';
// import { RegisterComponent } from './examples/register/register.component';
//import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
//import { PricingComponent } from './examples/pricing/pricing.component';
import { LrpcalcComponent } from './lrpcalc/lrpcalc.component';
import { CropComponent } from './crop/crop.component';
import { DonotsellmydataComponent } from './donotsellmydata/donotsellmydata.component';
import { GetaquoteComponent } from './getaquote/getaquote.component';


const routes: Routes =[
    
    //{ path: 'presentation',         component: PresentationComponent },
    // { path: 'components',           component: ComponentsComponent },
    // { path: 'sections',             component: SectionsComponent },
    // { path: 'nucleoicons',          component: NucleoiconsComponent },
    // { path: 'examples/aboutus',     component: AboutusComponent },
    // { path: 'examples/blogpost',    component: BlogpostComponent },
    // { path: 'examples/blogposts',   component: BlogpostsComponent },
    // { path: 'examples/contactus',   component: ContactusComponent },
    // { path: 'examples/ecommerce',   component: EcommerceComponent },
    // { path: 'examples/landing',     component: LandingComponent },
    // { path: 'examples/login',       component: LoginComponent },
    // { path: 'examples/pricing',     component: PricingComponent },
    // { path: 'examples/productpage', component: ProductpageComponent },
    // { path: 'examples/profile',     component: ProfileComponent },
    // { path: 'examples/register',    component: RegisterComponent }
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',                 component: HomeComponent },
    { path: 'lrpcalc',              component: LrpcalcComponent },
    { path: 'crop',                 component: CropComponent },
    { path: 'donotsellmydata',      component: DonotsellmydataComponent },
    { path: 'getaquote',            component: GetaquoteComponent }
];

const routerOptions: ExtraOptions = {
    anchorScrolling: "enabled",
    scrollPositionRestoration: 'enabled',
    useHash: true
  }

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        RouterModule.forRoot(routes,routerOptions)
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule { }
